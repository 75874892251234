.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.w-70 {
	width: 70% !important;
}

.w-33 {
	width: 33% !important;
}

.w-28 {
	width: 28% !important;
}

.w-37 {
	width: 37% !important;
}

.w-47 {
	width: 47% !important;
}

.w-90 {
	width: 90% !important;
}

.w-200px {
	width: 200px !important;
}

.w-15 {
	width: 15% !important;
}

.w-250px {
	width: 250px !important;
}

.w-20 {
	width: 20% !important;
}

.w-16 {
	width: 16% !important;
}

.w-150px {
	width: 150px !important;
}

.w-100px {
	width: 100px !important;
}

.w-34 {
	width: 34% !important;
}

.w-95 {
	width: 95% !important;
}

.max-w-100 {
	max-width: 100% !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.w-25 {
	width: 25% !important;
}

.w-320px {
	width: 320px !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-82px {
	width: 82px !important;
}

.min-w-250px {
	min-width: 250px !important;
}

.max-w-200px {
	max-width: 200px !important;
}

.w-50 {
	width: 50% !important;
}

.w-30 {
	width: 30% !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.w-32px {
	width: 32px !important;
}

.w-72px {
	width: 72px !important;
}

.w-40 {
	width: 40% !important;
}

.w-32 {
	width: 32% !important;
}

.w-48 {
	width: 48% !important;
}

.min-w-0px {
	min-width: 0px !important;
}

.w-89 {
	width: 89% !important;
}

