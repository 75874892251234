$btn-white-space: nowrap;
$primary: #006064;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #80cbc4,
	'primary': #006064,
	'primary-dark': #00363a,
	'primary-light': #428e92,
	'primary-text': #212121,
	'secondary-text': #757575,
	'text-on-primary': #000000,
	'text-on-accent': #ffffff,
	'danger': #dc3545,
	'info': #17a2b8,
	'success': #28a745,
	'warning': #fce359,
	'dark': #343a40,
	'light': #f8f9fa,
	'blue': #007bff,
	'cyan': #17a2b8,
	'gray': #6c757d,
	'green': #28a745,
	'indigo': #6610f2,
	'orange': #fd7e14,
	'pink': #e83e8c,
	'purple': #6f42c1,
	'red': #dc3545,
	'teal': #20c997,
	'white': #ffffff,
	'yellow': #ffc107,
	'oscurecido': #00000e5c,
) !default;
