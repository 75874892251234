.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.mt-20px {
	margin-top: 20px;
}

.mt-48px {
	margin-top: 48px;
}

.mt-15px {
	margin-top: 15px;
}

.mt-24px {
	margin-top: 24px;
}

.ms-20px {
	margin-left: 20px;
}

.mt-30px {
	margin-top: 30px;
}

.m-20px {
	margin: 20px;
}

.ms-50px {
	margin-left: 50px;
}

.mx-30px {
	margin-left: 30px;
	margin-right: 30px;
}

.mt-25px {
	margin-top: 25px;
}

.mt-12px {
	margin-top: 12px;
}

.me-10px {
	margin-right: 10px;
}

.mx-5px {
	margin-left: 5px;
	margin-right: 5px;
}

.m-24px {
	margin: 24px;
}

.me-20px {
	margin-right: 20px;
}

.ms-5px {
	margin-left: 5px;
}

.mt-10px {
	margin-top: 10px;
}

.ms-30px {
	margin-left: 30px;
}

.mb-75px {
	margin-bottom: 75px;
}

.mt-50px {
	margin-top: 50px;
}

.mb-10px {
	margin-bottom: 10px;
}

.m-50px {
	margin: 50px;
}

.mb--1px {
	margin-bottom: -1px;
}

.mt-32px {
	margin-top: 32px;
}

.me-16px {
	margin-right: 16px;
}

.mb-16px {
	margin-bottom: 16px;
}

.mb-64px {
	margin-bottom: 64px;
}

.mb-32px {
	margin-bottom: 32px;
}

.m-32px {
	margin: 32px;
}

.m-12px {
	margin: 12px;
}

.ms-16px {
	margin-left: 16px;
}

.mt-64px {
	margin-top: 64px;
}

.mt-16px {
	margin-top: 16px;
}

.my-30px {
	margin-top: 30px;
	margin-bottom: 30px;
}

.ms-10px {
	margin-left: 10px;
}

.m-30px {
	margin: 30px;
}

.me-15px {
	margin-right: 15px;
}

.my-20px {
	margin-top: 20px;
	margin-bottom: 20px;
}

.mb-20px {
	margin-bottom: 20px;
}

