.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: blackjack;
	src: url('../../assets/fonts/blackjack.otf');
}

.ff-blackjack {
font-family: blackjack;
}

@font-face {
	font-family: alexbrushregular;
	src: url('../../assets/fonts/AlexBrush-Regular.ttf');
}

.ff-alexbrushregular {
font-family: alexbrushregular;
}

@font-face {
	font-family: chrismaster;
	src: url('../../assets/fonts/chrisMaster.ttf');
}

.ff-chrismaster {
font-family: chrismaster;
}

@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: windsong;
	src: url('../../assets/fonts/Windsong.ttf');
}

.ff-windsong {
font-family: windsong;
}

@font-face {
	font-family: hillda;
	src: url('../../assets/fonts/Hillda.ttf');
}

.ff-hillda {
font-family: hillda;
}

@font-face {
	font-family: greatvibesregular;
	src: url('../../assets/fonts/GreatVibes-Regular.ttf');
}

.ff-greatvibesregular {
font-family: greatvibesregular;
}

@font-face {
	font-family: wanderlustlettersregular;
	src: url('../../assets/fonts/WanderlustLetters-Regular.ttf');
}

.ff-wanderlustlettersregular {
font-family: wanderlustlettersregular;
}

@font-face {
	font-family: hurmegeometricsans3black;
	src: url('../../assets/fonts/HurmeGeometricSans3Black.otf');
}

.ff-hurmegeometricsans3black {
font-family: hurmegeometricsans3black;
}



.f-25px {
	font-size: 25px;
}

.f-40px {
	font-size: 40px;
}

.f-20px {
	font-size: 20px;
}

.f-30px {
	font-size: 30px;
}

.f-22px {
	font-size: 22px;
}

.f-10px {
	font-size: 10px;
}

.f-18px {
	font-size: 18px;
}


